<template>
<div class="main privacy container" id="main"><!-- Main Section-->
  <div class="row justify-content-center">
      <div class="col-md-10">
          <h1>Privacy Policy</h1>

          <h2>01. Introduction</h2>
          <p>CitronWorks (“CitronWorks”, “CitronWorks, LLC”, “our”, “us” or “we”) via our website (www.CitronWorks.com”, “site”, “website”) is committed to protecting the privacy and security of all individuals who use our site. Our goal is to provide an Internet experience that delivers the information, resources, and services that are most relevant and helpful to you. In order to achieve this goal, from time to time we may collect information during your visits. This Privacy Policy is designed to help you learn more about the personal information we collect, who we might share it with, and how we protect it. Your use of our website is subject to this Privacy Policy. By using our website, you are accepting the practices set out in this privacy.</p>
          <h2>02. Data Collection Policy</h2>
          <h3>Information We Collect</h3>
          <p>CitronWorks collects personal information supplied:</p>
          <ul>
              <li>by our users;</li>
              <li>from information collected through web server logs;</li>
              <li>when you interact with third party services available through CitronWorks like surveys, polls or other third-party research undertaken with your consent; and</li>
              <li>through cookies; and</li>
              <li>mobile devices</li>
          </ul>

          <h3>Information Supplied by Our Users</h3>
          <p>CitronWorks collects personal information, such as your e-mail address, name, home or work address and telephone number. CitronWorks may also collect demographic information, such as your ZIP code, age and gender. CitronWorks does not collect any of this unique information about you except when you specifically and knowingly provide such information.</p>
          <h3>Information from Web Server Logs</h3>
          <p>CitronWorks records and saves information about you from our web server logs such as your Internet [Protocol] (IP) address, the time day you visited and your browser type. This information is used to verify our records and to provide better services to you.</p>
          <h3>Information from Third Party Services</h3>
          <p>When you interact with third party services available through CitronWorks like surveys, polls or other third-party research undertaken with your consent.</p>
          <h3>Information from Cookies</h3>
          <p>In order to offer and provide you with personalized services, CitronWorks may use "cookies" to store and help track your information. A cookie is a piece of data that identifies you as a unique user. CitronWorks will not disclose its cookies to third parties except as required by a valid legal process such as a search warrant, subpoena, statute, or court order.</p>
          <div style="padding-left: 40px">
              <h3>Cookies used by our service providers.</h3>
              <p>Our service providers use cookies and those cookies may be stored on your computer when you visit our website.</p>
              <h3>Google Analytics</h3>
              <p>We use Google Analytics to analyze the use of our website.  Google Analytics gathers information about website use by means of cookies.  The information gathered relating to our website is used to create reports about the use of our website.  Google’s privacy policy is available at https://policies.google.com/privacy.</p>
              <p>The information generated by the cookie about your use of the website will be transmitted to and stored by Google on servers in the United States. If this website anonymizes IP addresses, your IP address will be truncated by Google within an EU member state or other EEA state before being transmitted to the US. Only in exceptional situations will your full IP address be transmitted to Google servers in the United States and truncated there. Google will use this information for the purpose of evaluating your use of the website, compiling reports on website activity for website operators and providing other services relating to website activity and internet usage. Google will not associate your IP address with any other data held by Google. You may refuse the use of cookies by selecting the appropriate settings on your browser, however, please note that if you do this you may not be able to use the full functionality of this website. By using this website, you consent to the processing of data about you by Google in the manner and for the purposes set out above. You can also prevent Google from collecting information (including your IP address) via cookies and processing this information by downloading this browser plugin and installing it: http://tools.google.com/dlpage/gaoptout.</p>
              
              <h3>reCAPTCHA</h3>
              <p>We use the reCAPTCHA service provided by Google to protect your submissions via internet submission forms on this site. This plugin checks if you are a person in order to prevent certain website functions from being (ab)used by spam bots (particularly comments). This plugin query includes the sending of the IP address and possibly other data required by Google for the Google reCAPTCHA service. For this purpose, your input will be communicated to and used by Google. However, your IP address is previously truncated by Google within member states of the European Union or in other states which are party to the agreement on the European Economic Area and is, as such, anonymized. Only in exceptional cases is a full IP address transmitted to a Google server in the United States and truncated there. On behalf of the operator of this website, Google will use this information to evaluate your use of this service. The IP address provided by reCaptcha from your browser shall not be merged with any other data from Google.</p>  
              <p>By using the reCAPTCHA service, you consent to the processing of data about you by Google in the manner and for the purposes set out above.</p>
              <p>This data collection is subject to the data protection regulations of Google. For more information about Google’s privacy policy, please visit: https://policies.google.com/privacy</p>
              
              <h3>Howuku</h3>
              <p>We use Howuku to analyze the use of our website.  Howuku gathers information about website use by means of cookies.  The information gathered relating to our website is used to create reports about the use of our website.  Howuku’s privacy policy is available at https://howuku.com/privacy</p>
          </div>
          <h3>Information from Mobile Devices</h3>
          <p>CitronWorks may collect information from mobile devices used to access this site, such as operating system and version information and IP address.</p>
          <h2>03. Shared Data Policy</h2>
          <h3>Information We Share</h3>
          <p>In the course of delivering its product, CitronWorks may share:</p>
          <ul>
              <li>Information about yourself that you share. This is the information that your connections, other professionals, and companies see about you. This includes your profile and your posts and communications on CitronWorks, or other features CitronWorks may offer which, if you opt-in by completing the on-boarding.</li>
              <li>You have control over what you share, and you can update information at any time.</li>
              <li>Communication. We help you communicate with connections and other professionals.</li>
              <li>Public Information. Information, such as your professional information, will be available to the public.</li>
              <li>Information Shared with Event Organizers, Groups and Employers. Event organizers (such as organizers of conventions) will have access to attendee’s information entered into CitronWorks, and attendees will be able to access each other’s contact information through CitronWorks.</li>
              <li>Contractors & Employers. Contractor/Employers will be able to enter and access contractor/employer data entered into CitronWorks.</li>
          </ul>
      
          <h3>Information That Will Not Be Shared</h3>

          <p>In the course of delivering its product, CitronWorks will not share:</p>
          <ul>
              <li>Private Activity. We also provide tools that you can use privately on CitronWorks (for example, searching, or adding notes for your information.) These actions and information are private and we don't share or distribute them to others on the website. Note that if you write a review you have the option to display the written review only to your own category (Contractor/Employer) or not.</li>

          </ul>
          
          <h2>04. Disclosures</h2>
          <h3>Use or Disclosure of your Personal Information to Third Parties and Co-Brand Partners</h3>
          <p>We do not sell, rent, or otherwise provide personally identifiable information to third parties without your consent except where it is necessary to carry out your instructions (to process your payment information, for example) or to share information with CitronWorks Platform or affiliates that provide services.</p>

          <h3>Compliance with Legal Process</h3>
          <p>We may disclose personal information if we are required to do so by law or if we in good faith believe that such action is necessary to:</p>
          <ul>
              <li>comply with the law or with legal process;</li>
              <li>protect and defend our rights and property;</li>
              <li>protect against misuse or unauthorized use of our web sites;</li>
              <li>protect the personal safety or property of our users or the public (among </li>
              <li>other things, this means that if you provide false information or attempt to pose as someone else, information about you may be disclosed as part of any investigation into your actions).</li>
          </ul>
          <p>CitronWorks also may be required to disclose an individual’s personal information in response to a lawful request by public authorities, including to meet national security or law enforcement requirements.</p>
          <h2>05. Data Security</h2>
          <h3>Content Submitted Or Made Available For Inclusion On The Service</h3>
          <p>With respect to content you submit or make available for inclusion on publicly accessible areas of the Service, this information will be made public on the Internet. "Publicly accessible" areas of the Service are those areas of the CitronWorks network of properties that are intended by CitronWorks to be available to the general public. By way of example, publicly accessible areas of the Service would include message boards, and Blogs.</p>
          <h3>Our Commitment to Data Security</h3>
          <p>To prevent unauthorized access, maintain data accuracy, and ensure the correct use of information, we have put in place appropriate physical, electronic, and managerial procedures to safeguard and secure the information we collect online.</p>
          <h3>Protecting Your Information</h3>
          <p>While CitronWorks is committed to protecting your personal information, no data transmission over the Internet can be guaranteed to be 100% secure. You are solely responsible for maintaining the secrecy of any passwords you set up and/or any account information.</p>
          <h3>Payments</h3>
          <p>We may provide paid products and/or services.  In that case, we use third-party services for payment processing (e.g. payment processors).</p>
          <p>We will not store or collect your payment card details.  That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy.  </p>
          <p>The payment processors we work with are:</p>
          <ul>
              <li>Braintree. Their Privacy Policy can be viewed at https://www.braintreepayments.com/legal/braintree-privacy-policy</li>
              <li>PayPal. Their Privacy Policy can be viewed at https://www.paypal.com/us/webapps/mpp/ua/privacy-full</li>

          </ul>
          
          

          <h3>Links to Other Sites</h3>
          <p>CitronWorks encourages you to review the privacy statements of Web sites you choose to visit from CitronWorks so that you can understand how those Web sites collect, use and share your information. CitronWorks is not responsible for the privacy statements or other content on Web sites outside of the CitronWorks site.</p>
          <h3>Children’s Privacy</h3>
          <p>Our service does not address anyone under the age of 18 (“Children”)  
          We do not knowingly collect personally identifiable information from anyone under the age of 18.  If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us.  If we become aware that we have collected personal information from children without verification or parental consent, we take steps to remove that information from our servers.</p>
          <h3>Correcting Inaccuracies</h3>
          <p>When you sign up for CitronWorks's products and service, we may send you an e-mail confirming your new account. The message will be sent to the address that you supplied to us at the time of sign-up and will describe ways for you to change or delete the account information provided. Remember to keep the confirmation e-mail as it will also contain information that will help you in case you run into problems accessing our services. We welcome your comments or questions. Send us an email to team@citronworks.com.</p>

          <h3>Changes to This Privacy Policy</h3>
          <p>We may update our Privacy Policy from time to time.  We will notify you of any changes by posting the new Privacy Policy on this page.  You are advised to review this Privacy Policy periodically for any changes.  Changes to this Privacy Policy are effective when they are posted on this page. </p> 
      </div>
  </div>  
</div>
</template>

<script>
  export default {
    metaInfo: {
      meta: [{
        name: 'title',
        content: 'Privacy Policy',
      },
      {
        name: 'robots',
        content: 'noindex',
      }],
    }
  }
</script>

<style scoped>
  .privacy h1 {
    font-size: 2.5rem;
    margin-bottom: 40px;
    color: #454545;
  }
  .privacy h2 {
    font-size: 1.75rem;
    margin: 40px 0 40px 0;
    color: #E87722;
    font-weight: 600;
  }
  .privacy h3 {
    font-size: 1.5rem;
    margin: 40px 0 20px 0;
    padding: 8px 0;
    border-bottom: 1px solid #E87722;
    line-height: 1.6;
  }
  .privacy ul {
    list-style: circle;
    padding-left: 30px;
    line-height: 1.6;
    margin-bottom: 30px;
  }
  .privacy li {
    margin-bottom: 5px;
    font-size: .9rem;
  }
  .privacy p {
    line-height: 1.6;
    margin-bottom: 20px;
  }
  .privacy {
    padding-top: 50px;
    padding-bottom: 50px;
  }
</style>